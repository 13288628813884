import React, { Fragment } from "react";
import PropTypes from "prop-types";
// import { withPrefix } from "gatsby";
// import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHeader from "../components/PageHeader";
import Gallery from "../components/Gallery";

export const ServicePageTemplate = ({
  content,
  contentComponent,
  // description,
  imagebg,
  serviceIcon,
  subServices,
  subServicesTitle,
  title,
  helmet,
  catalog,
  carousel,
  pdf
}) => {
  const PostContent = contentComponent || Content;

  return (
    <Fragment>
      <PageHeader title={title} paintColor={"blue"} />
      <section
        className="servicePage__body"
        style={
          imagebg
            ? {
                background: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${
                  imagebg.childImageSharp.fluid.src
                    ? imagebg.childImageSharp.fluid.src
                    : ""
                })`,
                backgroundSize: `cover`,
                backgroundRepeat: "no-repeat"
                // backgroundAttachment: `fixed`
              }
            : { background: "rgba(0,0,0,.7)" }
        }
      >
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              {/* <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1> */}
              {/* <p>{description}</p> */}
              {serviceIcon ? (
                <div className="has-text-centered">
                  <img src={`/img/${serviceIcon.relativePath}`} width="100px" />
                </div>
              ) : null}

              <PostContent content={content} className={"is-size-5"} />
              <div className="has-text-centered">
                <Link className="btn btn-outline-white" to="/contact">
                  Get started
                </Link>
              </div>
              {/* {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null} */}
            </div>
          </div>
        </div>
      </section>

      <div id="service-splatter">
        <section className="section">
          <div className="container">
            <div className="columns is-multiline is-centered">
              {subServices ? (
                <div className="column is-12">
                  {subServicesTitle ? (
                    <h2 className="is-size-4 has-text-centered">
                      {subServicesTitle}
                    </h2>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {subServices
                ? subServices.map(subService =>
                    subService.text && subService.image !== null ? (
                      <div
                        className="column is-2 has-text-centered"
                        key={subService.text}
                      >
                        <img
                          src={subService.image.childImageSharp.fluid.src}
                          style={{
                            width: "8em",
                            height: "8em"
                          }}
                        />
                        <h4 className="is-size-5">{subService.text}</h4>
                      </div>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </div>

            {catalog !== null ? (
              <div className="has-text-centered">
                {catalog.catalogpdf.pdf && catalog.catalogpdf.text ? (
                  <a
                    className="btn btn-outline"
                    href={catalog.catalogpdf.pdf.publicURL}
                    target="blank"
                  >
                    {catalog.catalogpdf.text}
                  </a>
                ) : null}
              </div>
            ) : (
              ""
            )}
          </div>
        </section>

        {carousel ? (
          <section className="section">
            <div className="has-text-centered">
              <h3 className="is-size-2 title-underline">{title}</h3>
            </div>
            <div className="columns is-multiline">
              <div className="column">
                <Gallery slides={carousel} />
              </div>
            </div>
          </section>
        ) : null}
      </div>
    </Fragment>
  );
};

ServicePageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const ServicePage = ({ data }) => {
  const { markdownRemark: post } = data;
  // const pdfURL = data.pdf.filter(el => el.node.publicURL.includes(post.frontmatter.catalog.catalogpdf.relativePath))

  return (
    <Layout>
      {console.log(data)}
      <ServicePageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        subServices={
          post.frontmatter.serviceOverview
            ? post.frontmatter.serviceOverview.subService
            : ""
        }
        subServicesTitle={
          post.frontmatter.serviceOverview
            ? post.frontmatter.serviceOverview.title
            : ""
        }
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        imagebg={post.frontmatter.imagebg}
        // catalog={
        //   post.frontmatter.catalog ? post.frontmatter.catalog.catalogpdf : ""
        // }
        catalog={post.frontmatter.catalog}
        serviceIcon={post.frontmatter.serviceicon}
        gallery={post.frontmatter.gallery}
        carousel={post.frontmatter.carousel}
        // pdf={
        //   post.frontmatter.catalog !== null
        //     ? data.pdf.edges.filter(el =>
        //         el.node.publicURL.includes(
        //           post.frontmatter.catalog.catalogpdf.pdf !== null
        //             ? post.frontmatter.catalog.catalogpdf.pdf.relativePath.replace(
        //                 ".pdf",
        //                 ""
        //               )
        //             : ""
        //         )
        //       )
        //     : ""
        // }
      />
    </Layout>
  );
};

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ServicePage;

export const pageQuery = graphql`
  query ServicePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        imagebg {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        serviceicon {
          relativePath
        }
        serviceOverview {
          title
          subService {
            text
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        catalog {
          catalogpdf {
            pdf {
              publicURL
            }
            text
          }
        }
        carousel {
          heading
          subHeading
          linkText
          linksTo
          galleryImgs {
            image {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    pdf: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

// commented out catalog
// catalog {
//   catalogpdf {
//     pdf {
//       relativePath
//     }
//     text
//   }
// }
